<template>
  <div id="online_session">

    <!-- Navside -->
    <!-- <NavSideOnlilneSessions /> -->

    <!-- Activate/Deactivate -->
    <div
      class="space-105"
    >

    </div>
<!--
    <div class="container-money hide">
      <h2 class="title-regular white mb-5">
        Online sessions</h2>
      <!-- Switch active, desactive
      <b-field>
        <div @click="ActivateOnlineSessions">
          <b-switch v-model="ActiveSwitch"
            class="text-basier-medium dark-blue-emitus mb-5"
            style="font-size:19;"
            true-value="Active"
            false-value="Desactive">
            {{ ActiveSwitch }}
          </b-switch>
        </div>
      </b-field>
      <p class="paragraph-content dark-gray-emitus">It is a long established fact that a reader will be distracted. he point of using Lorem Ipsum is that it has a more-or-less normal</p>
    </div>
-->
    <!-- Main wrapper -->
    <div id="formMoney"  class="disabledContent">

      <!-- Session Durations -->
      <div id="navSideProfile1b" class="container-money desactive_">
        <div class="mb-6">
          <h2 class="title-regular white">Tarifas</h2>
          <p
            class="paragraph-content gray-ultra-light">
            ¡Tu tiempo es oro! 🤑. Selecciona la duración y el precio de tus sesiones.  </p>
            <!-- <p class="mt-4 gray-ultra-light"> Selecciona la duración y el precio de tus sesiones. </p> -->
        </div>

        <Sessions
          :key="sessionsKey"
          @updateUserDurations="updateUserDurations"
          @refresh="refreshSessions"/>

      </div>

      <!-- Timezone -->
      <div
        id="timezone"
        class="my-6 container-money">
        <h2
          class="title-regular white mt-6 pt-6">
          Zona Horaria</h2>
        <p
          class="text-nunito gray-ultra-light">
          </p>
        <div
          style="position: relative;">
          <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.30859 14.2C12.345 14.2 15.6172 11.2451 15.6172 7.6C15.6172 3.95492 12.345 1 8.30859 1C4.27217 1 1 3.95492 1 7.6C1 11.2451 4.27217 14.2 8.30859 14.2Z" stroke="#25C16F" stroke-width="1.04" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M1 7.6001H15.6172" stroke="#25C16F" stroke-width="1.04" stroke-linecap="round" stroke-linejoin="round"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M8.3082 1C10.1363 2.80731 11.1752 5.15274 11.2316 7.6C11.1752 10.0473 10.1363 12.3927 8.3082 14.2C6.48012 12.3927 5.44122 10.0473 5.38477 7.6C5.44122 5.15274 6.48012 2.80731 8.3082 1Z" stroke="#25C16F" stroke-width="1.04" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>

          <select
            disabled
            name="zone"
            id="zone"
            ref="zone"
            class="text-basier my-6 select-time-zone"
            >
            <option selected>{{currentZone}}</option>
          </select>

        </div>
      </div>

      <!-- Calendar -->
      <Calendar
        id="navSideProfile2b"
        :key="calendarKey"
        @refresh="refreshCalendar"
      />

      <!-- Modal Custom Overwrites -->
      <div class="" style="max-width: 550px; margin: 0 auto;" id="custom_overwrites">
        <Overwrites id="navSideProfile3b" />
      </div>

    </div>
    <!-- notificacion cuando no tengo creado el online sessions -->

    <!-- v-if="modalActivedOnlineSessions" -->
    <!-- <div v-if="modalActivedOnlineSessions" class="not-online-sessions is-flex is-justify-content-center is-align-items-center">
      <div class="is-flex is-justify-content-center is-flex-direction-column has-text-centered is-align-items-center">
        <img src="@/assets/images/money/kyc/not-in-process.svg" alt="">
        <br><br>
        <span class="text-basier text-modal" style="font-weight: 300;">Make your profile available and start geting paid for online sessions. <br><br> You can turn it on/off whenever you want. </span>
        <button class="button-primary-extra-medium text-basier mt-6" @click="actOnlineSessions">Active Online Session</button>
      </div>
    </div> -->
    <div class="h-desktop-800px"></div>
  </div>
</template>

<script>
import Overwrites from '@/components/onlineSessions/overwrites'
import Sessions from '@/components/onlineSessions/sessions'
import Calendar from '@/components/onlineSessions/calendar'
import UserService from '@/services/UserService'
import CalendarService from '@/services/CalendarService'
import firebase from 'firebase/app'
// import NavSideOnlilneSessions from '@/components/onlineSessions/navSideOnlineSessions'
import 'firebase/auth'

import dayjs from 'dayjs'
var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')
dayjs.extend(utc)
dayjs.extend(timezone)
export default {
  name: 'OnlineSessions',
  data () {
    return {
      currentZone: '',
      user: {},
      ActiveSwitch: 'Desactive',
      checkboxGroup: [],
      authUser: null,
      sessions: false,
      calendarKey: 0,
      sessionsKey: 0,
      modalActivedOnlineSessions: false
    }
  },
  components: {
    Overwrites,
    Sessions,
    Calendar
    // NavSideOnlilneSessions
  },
  metaInfo: {
    title: 'emitus | Monetize your talent'
  },
  watch: {
    modalActivedOnlineSessions: function () {
      if (this.modalActivedOnlineSessions) {
        this.actOnlineSessions()
      }
    }
  },
  created () {
    firebase.auth().currentUser.getIdToken(true)
      .then(async idToken => {
        await UserService.getCurrentUser(idToken)
          .then(user => {
            this.user = user.data
            if (!this.user.calendar) {
              // const publicProfile = document.body
              // publicProfile.classList.add('noScroll')
              this.modalActivedOnlineSessions = true
            }
            if (this.user.calendar) {
              // const publicProfile = document.body
              // publicProfile.classList.add('siScroll')
              this.modalActivedOnlineSessions = false
            }
            if (this.user.monetization.sessions === true) {
              this.ActiveSwitch = 'Active'
              this.sessions = true
              document.getElementById('formMoney').classList.remove('disabledContent')
            }
          })
          .catch(error => {
            console.log(error, '<-----!')
          })
      })
    this.notify.$on('updateData', () => {
      this.sessionsKey++
    })
    this.notify.$on('notificationsActiveCalendar', () => {
      this.notificationCalendarErr('activa tu calendario')
    })
    this.notify.$on('notificationsActiveSessions', () => {
      this.notificationCalendarErr('activa sesiones')
    })
  },
  mounted () {
    // window.addEventListener('scroll', this.scrollSideNavvvv)
    this.currentZone = dayjs.tz.guess()
  },
  methods: {

    // Activate/Deactivate Online Sessions
    async ActivateOnlineSessions () {
      if (this.ActiveSwitch === 'Desactive') {
        // // If first time, create Calendar
        // if (!this.user.calendar) {
        //   await this.createCalendar()
        // }

        // Make visible the Main Wrapper
        document.getElementById('formMoney').classList.remove('disabledContent')

        // Update Monetization/OnlineSessions --> TRUE
        firebase.auth().currentUser.getIdToken(true)
          .then(async idToken => {
            await UserService.updateSessions(idToken, true)
            this.notify.$emit('updateData')
            // Refresh Calendar component
            this.calendarKey += 1
          })
          .catch(error => {
            console.log('Error updating monetization sessions-online', error)
          })
      } else {
        // Hide the Main Wrapper
        document.getElementById('formMoney').classList.add('disabledContent')

        // Update Monetization/OnlineSessions --> FALSE
        firebase.auth().currentUser.getIdToken(false)
          .then(async idToken => {
            await UserService.updateSessions(idToken, false)
            // Refresh Calendar component
            this.calendarKey += 1
          })
          .catch(error => {
            console.log('Error updating monetization sessions-online', error)
          })
      }
    },
    async actOnlineSessions () {
      await this.createCalendar()
      document.getElementById('formMoney').classList.remove('disabledContent')
      // const publicProfile = document.body
      // publicProfile.classList.toggle('noScroll')
      this.modalActivedOnlineSessions = false
      this.ActiveSwitch = 'Active'
      firebase.auth().currentUser.getIdToken(true)
        .then(async idToken => {
          await UserService.updateSessions(idToken, true)
          this.notify.$emit('updateData')
          // Refresh Calendar component
          this.calendarKey += 1
        })
        .catch(error => {
          console.log('Error updating monetization sessions-online', error)
        })
    },

    // UpdateDurations
    updateUserDurations (userData) {
      firebase.auth().currentUser.getIdToken(true)
        .then(async idToken => {
          try {
            await UserService.updateUser(idToken, userData)
            this.notificationCalendar('Sessions updated')
            this.refreshSessions()
            this.notify.$emit('updateData')
          } catch (error) {
            console.log('error updating user', error)
          }
        })
    },

    // Calendar Notifications
    notificationCalendarErr (err) {
      this.$buefy.toast.open({
        duration: 5000,
        message: err,
        position: 'is-top',
        type: 'is-danger'
      })
    },
    notificationCalendar (msg, response) {
      this.$buefy.toast.open({
        duration: 5000,
        message: msg,
        position: 'is-top',
        type: 'is-success'
      })
    },

    // Create Calendar -------
    async createCalendar () {
      try {
        const idToken = await firebase.auth().currentUser.getIdToken(true)
        const response = await CalendarService.createMyCalendar(idToken)
        // console.log(response, 'esto awui')
        this.notificationCalendar('Calendar created successfully', response)
      } catch (error) {
        this.notificationCalendarErr(error, 'error al crear el calendario')
      }
    },

    // Refresh Calendar -------
    async refreshCalendar () {
      this.calendarKey += 1
    },
    async refreshSessions () {
      this.sessionsKey += 1
    }
    // scrollSideNavvvv () {
    //   console.log('asdassssss')
    //   const resp = window.scrollY
    //   console.log(resp)
    //   if (resp > 1 && resp < 850) {
    //     document.getElementById('navSide_1').classList.add('seleccionado_onlineSessions')
    //   } else {
    //     document.getElementById('navSide_1').classList.remove('seleccionado_onlineSessions')
    //   }
    //   if (resp > 850 && resp < 1146) {
    //     document.getElementById('navSide_2').classList.add('seleccionado_onlineSessions')
    //   } else {
    //     document.getElementById('navSide_2').classList.remove('seleccionado_onlineSessions')
    //   }
    //   if (resp > 1146 && resp < 2600) {
    //     document.getElementById('navSide_3').classList.add('seleccionado_onlineSessions')
    //   } else {
    //     document.getElementById('navSide_3').classList.remove('seleccionado_onlineSessions')
    //   }
    //   if (resp > 2600) {
    //     document.getElementById('navSide_4').classList.add('seleccionado_onlineSessions')
    //   } else {
    //     document.getElementById('navSide_4').classList.remove('seleccionado_onlineSessions')
    //   }
    // },

    // // Navegation
    // clickCheckbox (e) {
    //   console.log(e)
    // },
    // click1 () {
    //   window.scroll(0, 212)
    // },
    // click2 () {
    //   window.scroll(0, 950)
    // },
    // click3 () {
    //   window.scroll(0, 1252)
    // },
    // click4 () {
    //   window.scroll(0, 2850)
    // }
  }
  // destroyed () {
  //   window.removeEventListener('scroll', this.scrollSideNav)
  // }
}

</script>

<style scoped>

.select-time-zone  {
  padding-left: 35px;
  color: #3dbe79;
  font-size: 15px;
  width: 293px;
  border: none;
  background: none;
  border-radius: 5px;
  margin: 0px auto;
  height: 44px;
}
.select-time-zone:focus {
  border: none;
}
.select-time-zone:hover {
  border: none!important;
}
.text-modal {
  max-width: 290px;
}

.not-online-sessions {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 4;
}
.title-navSide {
  font-size: 22px;
  line-height: 30px;
}

.navSide {
  position: fixed;
  top: 190px;
  left: calc(56% - 590px);
}

.navSide a {
    color: #9A9A9A;
    cursor: pointer;
    display: block;
    transition: 0.3s;
    text-decoration: none;
}
.navSide ul li {
    margin: 15px 0px;
}
.navSide ul li a:hover {
    color: #75b188;
}
.navSide ul li a:active {
    color: #75b188;
}

.nav-side-online-sessions ul li a:hover {
    color: #6E3DEA;
}
.nav-side-online-sessions ul li a:active {
    color: #6E3DEA;
}
.seleccionado_onlineSessions {
    color: #25C16F !important;
}

.select:not(.is-multiple):not(.is-loading)::after {
    border-color: #0C0D34;
}
.title-top-sessions img {
  margin-right: 26px;
  margin-left: 15px;
  width: 13.07px;
  height: 14px;
}
.title-top-sessions p {
  font-size: 20px;
  color: #0C0D34;
}
.text-nunito {
  font-size: 12px;
}

.select:not(.is-multiple) {
    height: 100%!important;
}
.field.has-addons .control:last-child:not(:only-child) .select select {
    height: 100%;
}
.disabledContent {
    pointer-events: none;
    opacity: 0.4;
}
#online_session .tabs ul {
  border-bottom: none!important;
}
#online_session .tabs a {
  justify-content: flex-end!important;
  border-bottom: none!important;
  padding: 0!important;
  margin: 15px 0 25px 0;
}
#online_session .tabs li.is-active a {
  border-bottom: none!important;
}
#online_session .tabs.is-centered ul {
  display: block!important;
}
.switch:not(.has-left-label) .control-label {
  padding: 0!important;
}
.switch {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  width: 96%;
}

.space-105 {
  height: 26px;
}

.hide {
  display: none;
}

@media (max-width: 1031px) {
  #online_session {
    width: 85%;
    margin: 0 auto;
  }
}
@media (min-width: 1031px) {
  .container-money {
    margin-top: 47px;
  }

  .space-105 {
    height: 95px;
  }
  .h-desktop-800px {
    height: 477px;
  }
}

</style>
