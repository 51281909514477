<template>
  <div id="sessions" class="is-flex is-flex-direction-column">

    <!-- minutes and duration of sessions (15min) -->
    <div class="sessionBlock is-flex is-align-items-center session-mobile">
      <div class="is-flex w-session-mobile">
        <b-checkbox
          v-model="checkbox15"
        >
          15 minutos
        </b-checkbox>
      </div>
      <b-field
        class="w-session-input-mobile"
        v-if="checkbox15"
      >
        <b-input
          v-model="price15"
          type="number"
          placeholder="0">
          </b-input>
        <!-- <p>{{durations_price_1}}</p> -->
        <b-select disabled="disabled" v-model="currency" placeholder="USD">
          <option :value="currency">{{currency}}</option>
          <!-- <option value="EUR">EUR</option> -->
        </b-select>
      </b-field>
    </div>

    <!-- minutes and duration of sessions (30min) -->
    <div class="sessionBlock is-flex is-align-items-center session-mobile">
      <div class="is-flex w-session-mobile">
        <b-checkbox
          v-model="checkbox30"
        >
          30 minutos
        </b-checkbox>
      </div>
      <b-field
        class="w-session-input-mobile"
        v-if="checkbox30">
        <b-input
          v-model="price30"
          type="number"
          placeholder="0">
          </b-input>
        <!-- <p>{{durations_price_1}}</p> -->
        <b-select disabled="disabled" v-model="currency" placeholder="USD">
          <option :value="currency">{{currency}}</option>
          <!-- <option value="EUR">EUR</option> -->
        </b-select>
      </b-field>
    </div>

    <!-- minutes and duration of sessions (45min) -->
    <div class="sessionBlock is-flex is-align-items-center session-mobile">
      <div class="is-flex w-session-mobile">
        <b-checkbox
          v-model="checkbox45"
        >
          45 minutos
        </b-checkbox>
      </div>
      <b-field
        class="w-session-input-mobile"
        v-if="checkbox45">
        <b-input
          v-model="price45"
          type="number"
          placeholder="0">
          </b-input>
        <!-- <p>{{durations_price_1}}</p> -->
        <b-select disabled="disabled" v-model="currency" placeholder="USD">
          <option :value="currency">{{currency}}</option>
          <!-- <option value="EUR">EUR</option> -->
        </b-select>
      </b-field>
    </div>

    <!-- minutes and duration of sessions (60min) -->
    <div class="sessionBlock is-flex is-align-items-center session-mobile">
      <div class="is-flex w-session-mobile">
        <b-checkbox
          v-model="checkbox60"
        >
          60 minutos
        </b-checkbox>
      </div>
      <b-field
        class="w-session-input-mobile"
        v-if="checkbox60">
        <b-input
          v-model="price60"
          type="number"
          placeholder="0">
          </b-input>
        <!-- <p>{{durations_price_1}}</p> -->
        <b-select disabled="disabled" v-model="currency" placeholder="USD">
          <option :value="currency">{{currency}}</option>
          <!-- <option value="EUR">EUR</option> -->
        </b-select>
      </b-field>
    </div>

    <!-- minutes and duration of sessions (90min) -->
    <div class="sessionBlock is-flex is-align-items-center session-mobile">
      <div class="is-flex w-session-mobile">
        <b-checkbox
          v-model="checkbox90"
        >
          90 minutos
        </b-checkbox>
      </div>
      <b-field
        class="w-session-input-mobile"
        v-if="checkbox90">
        <b-input
          v-model="price90"
          type="number"
          placeholder="0">
          </b-input>
        <!-- <p>{{durations_price_1}}</p> -->
        <b-select disabled="disabled" v-model="currency" placeholder="USD">
          <option :value="currency">{{currency}}</option>
          <!-- <option value="EUR">EUR</option> -->
        </b-select>
      </b-field>
    </div>

    <!-- minutes and duration of sessions (120min) -->
    <div class="sessionBlock is-flex is-align-items-center session-mobile">
      <div class="is-flex w-session-mobile">
        <b-checkbox
          v-model="checkbox120"
        >
          120 minutos
        </b-checkbox>
      </div>
      <b-field
        class="w-session-input-mobile"
        v-if="checkbox120">
        <b-input
          v-model="price120"
          type="number"
          placeholder="0">
          </b-input>
        <!-- <p>{{durations_price_1}}</p> -->
        <b-select disabled="disabled" v-model="currency" placeholder="USD">
          <option :value="currency">{{currency}}</option>
          <!-- <option value="EUR">EUR</option> -->
        </b-select>
      </b-field>
    </div>

    <button class="mt-6 button-primary-extra-medium" @click="updateUserDurations">Guardar Tarifas</button>
  </div>
</template>

<script>
import UserService from '@/services/UserService'
import firebase from 'firebase/app'
import 'firebase/auth'

export default {
  data () {
    return {
      user: {},
      currency: '',
      durations: [],
      durationsp: [],
      checkbox15: false,
      checkbox30: false,
      checkbox45: false,
      checkbox60: false,
      checkbox90: false,
      checkbox120: false,
      price15: '',
      price30: '',
      price45: '',
      price60: '',
      price90: '',
      price120: ''
    }
  },
  created () {
    firebase.auth().currentUser.getIdToken(true)
      .then(async idToken => {
        await UserService.getCurrentUser(idToken)
          .then(user => {
            this.user = user.data
            this.durations = this.user.durations
            this.currency = this.user.currency
            // console.log(this.user.currency, '<<<<<<')
          })
      })
  },
  watch: {
    durations: function () {
      const positions = this.durations.map(function (e) { return e.minutes })
      if (positions.indexOf(15) >= 0) {
        this.checkbox15 = true
        this.price15 = this.durations[positions.indexOf(15)].price
      }
      if (positions.indexOf(30) >= 0) {
        this.checkbox30 = true
        this.price30 = this.durations[positions.indexOf(30)].price
      }
      if (positions.indexOf(45) >= 0) {
        this.checkbox45 = true
        this.price45 = this.durations[positions.indexOf(45)].price
      }
      if (positions.indexOf(60) >= 0) {
        this.checkbox60 = true
        this.price60 = this.durations[positions.indexOf(60)].price
      }
      if (positions.indexOf(90) >= 0) {
        this.checkbox90 = true
        this.price90 = this.durations[positions.indexOf(90)].price
      }
      if (positions.indexOf(120) >= 0) {
        this.checkbox120 = true
        this.price120 = this.durations[positions.indexOf(120)].price
      }
    }
  },
  methods: {
    // Validate and update durations
    updateUserDurations () {
      if (this.checkbox15 && this.price15 > 3) {
        this.durationsp.push({ minutes: 15, price: this.price15 })
      } else if (this.checkbox15 && this.price15 === '') {
        this.notificationSessionsErr('Missing price for 15 min session')
        this.durationsp = []
        return
      }
      if (this.checkbox30 && this.price30 > 3) {
        this.durationsp.push({ minutes: 30, price: this.price30 })
      } else if (this.checkbox30 && this.price30 === '') {
        this.notificationSessionsErr('Missing price for 30 min session')
        this.durationsp = []
        return
      }
      if (this.checkbox45 && this.price45 > 3) {
        this.durationsp.push({ minutes: 45, price: this.price45 })
      } else if (this.checkbox45 && this.price45 === '') {
        this.notificationSessionsErr('Missing price for 45 min session')
        this.durationsp = []
        return
      }
      if (this.checkbox60 && this.price60 > 3) {
        this.durationsp.push({ minutes: 60, price: this.price60 })
      } else if (this.checkbox60 && this.price60 === '') {
        this.notificationSessionsErr('Missing price for 60 min session')
        this.durationsp = []
        return
      }
      if (this.checkbox90 && this.price90 > 3) {
        this.durationsp.push({ minutes: 90, price: this.price90 })
      } else if (this.checkbox90 && this.price90 === '') {
        this.notificationSessionsErr('Missing price for 90 min session')
        this.durationsp = []
        return
      }
      if (this.checkbox120 && this.price120 > 3) {
        this.durationsp.push({ minutes: 120, price: this.price120 })
      } else if (this.checkbox120 && this.price120 === '') {
        this.notificationSessionsErr('Missing price for 120 min session')
        this.durationsp = []
        return
      }
      if ((this.price15 <= 3 && this.price15 !== '') ||
          (this.price30 <= 3 && this.price30 !== '') ||
          (this.price45 <= 3 && this.price45 !== '') ||
          (this.price60 <= 3 && this.price60 !== '') ||
          (this.price90 <= 3 && this.price90 !== '') ||
          (this.price120 <= 3 && this.price120 !== '')) {
        this.notificationSessionsErr('It cannot be less than 3 EUR')
        this.durationsp = []
      } else {
        this.$emit('updateUserDurations', {
          durations: this.durationsp
        })
      }
    },

    // session notification
    notificationSessions (msg) {
      this.$buefy.toast.open({
        duration: 4000,
        message: msg,
        position: 'is-top',
        type: 'is-success'
      })
    },
    notificationSessionsErr (msg) {
      this.$buefy.toast.open({
        duration: 4000,
        message: msg,
        position: 'is-top',
        type: 'is-danger'
      })
    }
  }
}
</script>

<style>
.sessionBlock {
  /* height: 48px; */
  margin-bottom: 49px;
}

.input_incomplete {
  border: 1.5px solid red;
}
#sessions .select:not(.is-multiple):not(.is-loading)::after {
  display: none;
}
.session-mobile,
.session-mobile input,
.session-mobile select option {
  color: rgba(255, 255, 255, 0.5);
}

.session-mobile {
  flex-direction: column;
  gap: 32px;
}
@media (max-width: 768px) {
  .w-session-mobile,
  .w-session-input-mobile {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .session-mobile {
    flex-direction: row;
    justify-content: space-between;
    /* gap: 75px; */
  }
  .w-session-input-mobile {
    width: 171px;
  }
  #sessions {
    width: 68%;
  }
  .sessionBlock {
    height: 48px;
    margin-bottom: 15px;
  }
  .session-mobile {
    gap: 30px;
  }

}

</style>
