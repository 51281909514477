import axios from 'axios'

const apiClient = axios.create({
  baseURL: `${process.env.VUE_APP_CONNECTION_URL}`,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

export default {
  async addOverwrite (idToken, newOverwrite) {
    return await apiClient.post('/overwrites', newOverwrite,
      {
        headers: { idToken: idToken }
      })
  },
  async getMyOverwrites (idToken) {
    return await apiClient.get('/overwrites/current',
      {
        headers: { idToken: idToken }
      })
  },
  async getOneOverwrite (idToken, overwriteId) {
    return await apiClient.get('/overwrites/' + overwriteId,
      {
        headers: { idToken: idToken }
      })
  },
  async deleteMyOverwrite (idToken, overwriteId) {
    return await apiClient.delete('/overwrites/' + overwriteId,
      {
        headers: { idToken: idToken }
      })
  }
}
