<template>
  <div id="overwrites_container" style="padding-bottom: 150px;">
    <!-- Title -->
    <div class="custom-overwrites">
      <div class="custom-overwrites-container">
        <h2
          class="title-regular white">
          Días Especiales
        </h2>
        <p
          class="paragraph-content gray-ultra-light description-overwrites mb-6">
          Modifica tu disponibilidad en fechas específicas. Perfecto para vacaciones 🏝</p>

        <!-- Add New Overwrite -->
        <a
          class="text-basier mt-6 link-text-dark"
          label="Add new overwrite"
          type="is-primary"
          @click="overwriteModalActive = true">
          + Añade una fecha especial
          </a>
      </div>
    </div>

    <b-modal v-model="overwriteModalActive">
      <div class="cancel_modal_overwrite">
        <img @click="cancelOverwrite" src="@/assets/images/onlineSession/cancel_modal.svg" alt="cancel overwrites">
      </div>
      <div class="custom-overwrites-modal" style="width: 100%;">
        <p class="title-modal-overwrite text-basier">Elige la fecha que quieres sobre escribir </p>
        <div class="custom-overwrites-modal-container">
          <!-- Calendar -->
          <b-datepicker
            v-model="newOverwriteDate"
            :min-date="minDate"
            inline>
          </b-datepicker>
          <!-- Horas disponibles -->
          <div class="is-flex is-flex-direction-column w-calendar box-available" style="width: 100%;">
            <div class="custom-overwrites-modal-2">
              <div>
                <div class="custom-overwrites-content container-content-overwrites">
                  <p>Horas disponibles</p>
                  <img @click="createBlockNewOverwrite" src="@/assets/images/onlineSession/add.svg" alt="add">
                </div>
                <div class="new-overwrites-blocks">
                  <div v-for="(block, index) in newOverwriteBlocks"
                      :key="`block-${index}`" class="custom-overwrites-content-2 container-content-overwrites">
                    <div id="input_overwrites" class="is-flex is-align-items-center is-justify-content-space-between">
                      <b-timepicker
                        style="width: 40%!important;"
                        v-model="newOverwriteBlocks[index].start"
                        inline
                        :incrementMinutes="minutesGranularity"
                      ></b-timepicker>
                      <span>to</span>
                      <b-timepicker
                        style="width: 40%!important;"
                        v-model="newOverwriteBlocks[index].end"
                        inline
                        :incrementMinutes="minutesGranularity"
                      ></b-timepicker>
                      <img @click="deleteBlockNewOverwrite(index)" src="@/assets/images/onlineSession/trash.svg" alt="trash">
                    </div>
                  </div>
                </div>
              </div>
              <div class="set-unavailable-overwrites text-basier">
                <a @click="setUnavailable">Hacer que este día no esté disponible</a>
              </div>
            </div>
            <div class="custom-overwrites-modal-3">
              <div class="custom-overwrites-content container-content-overwrites custom-save">
                <p @click="cancelOverwrite" class="Basier-normal-regular black" style="text-decoration: underline; cursor: pointer;">Cancelar</p>
                <button @click="addOverwrite" class="Basier-size-medium-bold button-primary-mini" >Guardar</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <div id="overwrites">
      <div
        class="card overwriteCard is-flex is-justify-content-space-between"
        v-for="(overwrite, index) in overwrites"
        :key="`overwrite-${index}`"
        >
        <div @click="ModalUpdateMyOverwrite(overwrite._id)" class="is-flex is-justify-content-space-between" style="width: 85%;">
          <p style="color: #fff;" class="text-basier">{{ overwrite.date }}</p>
          <ul>
            <p v-if="overwrite.blocks.length === 0">unavailable this date</p>
            <li
              class="text-basier"
              style="color: #fff;"
              v-for="(block, indexb) in overwrite.blocks"
              :key="`block-${indexb}`">
              {{ block.start }} - {{ block.end }}
            </li>
          </ul>
          <p class="text-basier" style="color: #fff; font-size: 12px; cursor: pointer">
            <img style="width: 10px;" src="https://emituscdn.com/icon-edit-white.svg" alt="add">
            Edit
          </p>
        </div>
        <div class="mr-2" style="cursor: pointer">
          <img @click="deleteMyOverwrite(overwrite._id)" style="width: 13px;" src="https://emituscdn.com/trash-white.svg" alt="trash">
        </div>
      </div>
    </div>

    <b-modal v-model="overwriteUpdateModalActive">
      <div class="cancel_modal_overwrite">
        <img @click="cancelUpdateOverwrite" src="@/assets/images/onlineSession/cancel_modal.svg" alt="cancel overwrites">
      </div>
      <div class="custom-overwrites-modal" style="width: 100%;">
        <p class="title-modal-overwrite text-basier">Elige la fecha que quieres sobre escribir </p>
        <div class="custom-overwrites-modal-container">
          <!-- Calendar -->
          <b-datepicker
            v-model="updateOverwriteDate"
            :unselectable-days-of-week="unselectableDates"
            inline>
          </b-datepicker>
          <!-- Horas disponibles -->
          <div class="is-flex is-flex-direction-column box-available">
            <div class="custom-overwrites-modal-2">
              <div>
                <div class="custom-overwrites-content container-content-overwrites">
                  <p>Horas disponibles</p>
                  <img @click="createBlockUpdateOverwrite" src="https://emituscdn.com/icon-edit-white.svg" alt="add">
                </div>
                <div class="new-overwrites-blocks">
                  <div v-for="(block, index) in updateOverwriteBlocks"
                      :key="`block-${index}`" class="custom-overwrites-content-2 container-content-overwrites">
                    <div id="input_overwrites"  class="is-flex is-align-items-center is-justify-content-space-between">
                      <b-timepicker
                        style="width: 40%!important;"
                        v-model="updateOverwriteBlocks[index].start"
                        inline
                        :incrementMinutes="minutesGranularity"
                      ></b-timepicker>
                      <span>to</span>
                      <b-timepicker
                        style="width: 40%!important;"
                        v-model="updateOverwriteBlocks[index].end"
                        inline
                        :incrementMinutes="minutesGranularity"
                      ></b-timepicker>
                      <img @click="deleteBlockUpdateOverwrite(index)" src="https://emituscdn.com/icon-edit-black.svg" alt="trash">
                    </div>
                  </div>
                </div>
              </div>
              <div class="set-unavailable-overwrites text-basier">
                <a @click="setUnavailable">Hacer que este día no esté disponible</a>
              </div>
            </div>
            <div class="custom-overwrites-modal-3">
              <div class="custom-overwrites-content container-content-overwrites custom-save">
                <p @click="cancelUpdateOverwrite" class="Basier-normal-regular black" style="text-decoration: underline; cursor: pointer;">cancel</p>
                <button @click="updateOverwrites" class="Basier-size-medium-bold button-primary-mini" >Guardar</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import OverwriteService from '@/services/OverwritesService'
import firebase from 'firebase/app'
import 'firebase/auth'
import dayjs from 'dayjs'
var utc = require('dayjs/plugin/utc')

dayjs.extend(utc)

export default {
  name: 'AvailabilityDev',
  data () {
    return {
      minDate: new Date(),
      authUser: null,
      overwriteModalActive: false,
      overwrites: {},
      newOverwriteBlocks: [],
      newOverwriteDate: new Date(),
      newOverwrite: {},
      minutesGranularity: 30,
      overwriteUpdateModalActive: false,
      updateOverwriteBlocks: [],
      updateOverwriteDate: new Date(),
      updateOverwrite: {},

      unselectableDates: [0, 1, 2, 3, 4, 5, 6]
    }
  },
  async created () {
    await this.showOverwrites()
  },

  methods: {
    // Show overwrites
    async showOverwrites () {
      try {
        // Traigo los Overwrites
        const idToken = await firebase.auth().currentUser.getIdToken(true)
        const response = await OverwriteService.getMyOverwrites(idToken)
        const today = new Date()
        const overwrites = response.data

        // Ordeno los overlays
        const orderedOverwrites = await overwrites.sort(function (a, b) {
          return new Date(a.date) - new Date(b.date)
        })

        // Filter
        const filteredOverwrites = orderedOverwrites.filter(overwrite => {
          const dateOK = dayjs(overwrite.date).toDate()
          return dateOK > today
        })

        // Nice format
        for (let i = 0; i < filteredOverwrites.length; i++) {
          filteredOverwrites[i].date = dayjs.utc(filteredOverwrites[i].date).format('MMM D')
          for (let j = 0; j < filteredOverwrites[i].blocks.length; j++) {
            filteredOverwrites[i].blocks[j].start = dayjs(filteredOverwrites[i].blocks[j].start).format('HH:mm')
            filteredOverwrites[i].blocks[j].end = dayjs(filteredOverwrites[i].blocks[j].end).format('HH:mm')
          }
        }

        // Asigno a Data
        this.overwrites = filteredOverwrites
      } catch (error) {
        this.notificationOverwritesErr(error)
      }
    },
    // notification
    notificationOverwrites (msg) {
      this.$buefy.toast.open({
        duration: 5000,
        message: msg,
        position: 'is-top',
        type: 'is-success'
      })
    },
    notificationOverwritesErr (err) {
      this.$buefy.toast.open({
        duration: 5000,
        message: err,
        position: 'is-top',
        type: 'is-danger'
      })
    },

    // Add New Block to New Overlap
    async createBlockNewOverwrite () {
      const now = new Date()
      const newForBlock = dayjs(now).set('hour', 0).set('minute', 0).set('second', 0).set('milliseconds', 0).toDate()
      // console.log(newForBlock)
      this.newOverwriteBlocks.push({ start: newForBlock, end: newForBlock })
    },
    async deleteBlockNewOverwrite (index) {
      this.newOverwriteBlocks.splice(index, 1)
    },

    // Cancel Overwrite
    async cancelOverwrite () {
      this.newOverwriteBlocks = []
      this.overwriteModalActive = false
    },

    // Add New Overwrite
    async addOverwrite () {
      try {
        const idToken = await firebase.auth().currentUser.getIdToken(true)

        const date = dayjs(this.newOverwriteDate)
        // console.log('--> DayJs Date', date, typeof date)
        const y = date.$y
        const M = date.$M + 1
        const D = date.$D
        const fixedDatenewOverwriteBlocks = []

        for (let i = 0; i < this.newOverwriteBlocks.length; i++) {
          const startF = dayjs(this.newOverwriteBlocks[i].start).set('date', D)
          const endF = dayjs(this.newOverwriteBlocks[i].end).set('date', D)

          fixedDatenewOverwriteBlocks.push({ start: startF, end: endF })
        }

        // console.log('-->> fixedDatenewOverwriteBlocks', fixedDatenewOverwriteBlocks)

        const newOverwrite = {
          year: y,
          month: M,
          day: D,
          blocks: fixedDatenewOverwriteBlocks
        }
        await OverwriteService.addOverwrite(idToken, newOverwrite)
        this.showOverwrites()
        this.overwriteModalActive = false
      } catch (error) {
        this.danger(error.response.data.msg)
      }
    },

    // Danger Toast
    danger (msg) {
      this.$buefy.toast.open({
        duration: 5000,
        message: msg,
        position: 'is-bottom',
        type: 'is-danger'
      })
    },

    // delete Overwrite
    async deleteMyOverwrite (overwriteId) {
      const idToken = await firebase.auth().currentUser.getIdToken(true)
      const response = await OverwriteService.deleteMyOverwrite(idToken, overwriteId)
      this.notificationOverwrites(response.data.msg)
      this.showOverwrites()
      this.newOverwriteBlocks = []
    },

    // Show Modal update Overwrite
    async ModalUpdateMyOverwrite (overwriteId) {
      const idToken = await firebase.auth().currentUser.getIdToken(true)
      const response = await OverwriteService.getOneOverwrite(idToken, overwriteId)
      const overwrite = response.data

      this.overwriteUpdateModalActive = true
      this.updateOverwriteDate = dayjs(overwrite.date).toDate()

      for (let i = 0; i < overwrite.blocks.length; i++) {
        overwrite.blocks[i].start = dayjs(overwrite.blocks[i].start).toDate()
        overwrite.blocks[i].end = dayjs(overwrite.blocks[i].end).toDate()
      }

      this.updateOverwriteBlocks = overwrite.blocks
    },

    // Add New Block to Update Overlap
    async createBlockUpdateOverwrite () {
      const now = new Date()
      const newForBlock = dayjs(now).set('hour', 0).set('minute', 0).set('second', 0).toDate()
      // console.log(newForBlock)
      this.updateOverwriteBlocks.push({ start: newForBlock, end: newForBlock })
    },
    async deleteBlockUpdateOverwrite (index) {
      this.updateOverwriteBlocks.splice(index, 1)
    },

    // Cancel Update Overwrite
    async cancelUpdateOverwrite () {
      this.updateOverwriteBlocks = []
      this.overwriteUpdateModalActive = false
    },

    // Update Overwrite
    async updateOverwrites () {
      try {
        const idToken = await firebase.auth().currentUser.getIdToken(true)

        const date = dayjs(this.updateOverwriteDate)
        // console.log('--> DayJs Date', date, typeof date)
        const y = date.$y
        const M = date.$M + 1
        const D = date.$D + 1
        const fixedDateupdateOverwriteBlocks = []

        for (let i = 0; i < this.updateOverwriteBlocks.length; i++) {
          const startF = dayjs(this.updateOverwriteBlocks[i].start).set('date', D)
          const endF = dayjs(this.updateOverwriteBlocks[i].end).set('date', D)

          fixedDateupdateOverwriteBlocks.push({ start: startF, end: endF })
        }

        // console.log('-->> fixedDateupdateOverwriteBlocks', fixedDateupdateOverwriteBlocks)

        const updatedOverwrite = {
          year: y,
          month: M,
          day: D,
          blocks: fixedDateupdateOverwriteBlocks
        }
        await OverwriteService.addOverwrite(idToken, updatedOverwrite)
        this.showOverwrites()
        this.overwriteUpdateModalActive = false
      } catch (error) {
        this.danger(error.response.data.msg)
      }
    },

    // Set Unavailable
    async setUnavailable () {
      this.updateOverwriteBlocks = []
      this.newOverwriteBlocks = []
      this.updateOverwrites()
      this.overwriteModalActive = false
      this.overwriteUpdateModalActive = false
    },

    scrollTop () {
      this.overwriteModalActive = true
      // document.getElementById('modal_overwrite_1').addEventListener(window.scroll(0, 0))
      // window.scroll(0, 0)
    }
  }

}
</script>

<style>

.description-overwrites {
  max-width: 336px;
  line-height: 30px !important;
}
#overwrites_container .dropdown.is-inline .dropdown-menu {
  border: 1px solid #ECECEC;
}
#overwrites_container .modal .modal-content {
  height: 100%;
}
.cancel_modal_overwrite {
  z-index: 31;
  position: fixed;
  top: 0px;
  left: 0px;
  height: 58px;
  background: #fff;
  /* right: 27px; */
  cursor: pointer;
  /*box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.07);*/
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.cancel_modal_overwrite img {
  width: 27px;
  height: 27px;
  margin-right: 27px;
}
.title-modal-overwrite {
  line-height: 30px;
  color: #0D1239;
  width: 280px;
  margin: 0 auto;
}
#overwrites_container .box-available {
  margin-top: 22px;
  border: 1px solid #ECECEC;
}
.custom-save {
  max-width: 420px;
}
#overwrites_container .timepicker .dropdown-content .control .select select {
  padding: 0px;
}
#overwrites {
  margin-top: 47px;
}
#overwrites li {
  display: flex;
  gap: 15px;
  margin-bottom: 10px;
}
#overwrites .overwriteCard {
  padding: 16px 15px 15px 12px;
  border: 1px solid #868686;
  border-radius: 5px;
  margin-bottom: 10px;
  box-shadow: none;
  background: none;
}
#input_overwrites .select:not(.is-multiple) {
  height: 40px!important;
}
#input_overwrites .control {
  display: flex;
  align-items: center;
}
#input_overwrites .timepicker {
  border: 1px solid #D4D4D4;
  box-sizing: border-box;
  border-radius: 5px;
}
#input_overwrites .dropdown.is-inline .dropdown-menu {
  width: 100%;
  border: 1px solid #ECECEC;
  box-sizing: border-box;
}
#input_overwrites .dropdown-content {
  box-shadow: none;
}
.custom-overwrites-modal .pagination.is-centered .pagination-previous,
.custom-overwrites-modal .pagination.is-centered .pagination-next,
.custom-overwrites-modal .pagination.is-centered .pagination-list,
.custom-overwrites-modal .field.has-addons .control:first-child:not(:only-child) .select select,
.custom-overwrites-modal .field.has-addons .control:last-child:not(:only-child) .select select {
  border: none!important;
}
.custom-overwrites-modal .select:not(.is-multiple):not(.is-loading)::after {
  display: none!important;
}
.custom-overwrites-modal .mdi-24px.mdi:before {
  color: rgb(164, 164, 164);
}
.custom-overwrites-modal .datepicker .datepicker-footer {
  display: none;
}
.custom-overwrites-modal-2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #fff;
  width: 100%;
  /* height: 233px; */
  margin: 6px auto 80px auto;
  border-radius: 5px;
  padding-top: 26px;
  max-width: 322px;
}
.custom-overwrites-modal-3 {
  background: #fff;
  width: 100%;
  height: 80px;
  margin: 0px auto;
  border-radius: 5px;
  /* padding-top: 26px; */
  position: fixed;
  bottom: 0px;
  left: 0;
  box-shadow: 0px -2px 14px rgba(0, 0, 0, 0.09);
}
.custom-overwrites-modal-3 .container-content-overwrites {
  margin: 0 auto;
}
.custom-overwrites-modal-3 .custom-overwrites-content {
  align-items: center;
  margin-bottom: 0px;
  height: 100%;
}
.custom-overwrites-content {
  display: flex;
  justify-content: space-between;
  margin-bottom: 26px;
  align-items: baseline;
}
.container-content-overwrites {
  width: 90%;
  margin: 5px auto;
}
.custom-overwrites-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100%!important;
  background: #fff;
  z-index: 30;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: scroll;

}
.set-unavailable-overwrites {
    font-weight: normal;
    font-size: 14px;
    line-height: 30px;
    text-decoration-line: underline;
    color: #6E3DEA;
    margin: 37px auto 31px 28px;
}
@media (max-width: 1023px) {
  .title-modal-overwrite {
    margin-top: 100px;
    font-size: 17px;
  }
  /* #overwrites_container {
    overflow: scroll;
  } */
  #input_overwrites .timepicker .dropdown-content .control .select select {
    padding: 2px;
  }
  #input_overwrites .dropdown-content {
    padding: 0px;
  }
  .custom-overwrites-modal-container {
    margin: 0 auto !important;
    height: 100%;
  }
  .new-overwrites-blocks {
    /* height: 109px; */
    overflow: auto;
  }
  .custom-overwrites-modal{
    padding-bottom: 75px;
    padding-top:60px;
    overflow: scroll;
  }
  #overwrites_container .datepicker {
    margin-top: 25px;
  }
}
@media (min-width: 768px) {
  .cancel_modal_overwrite img{
    margin-right: 67px;
  }
  .custom-overwrites-modal {
    padding-top: 120px;
  }
  .title-modal-overwrite {
    font-size: 21px;
    margin-bottom: 36px;
  }
  #overwrites {
    margin-top: 71px;
  }
  #overwrites li {
    gap: 39px;
  }
  .new-overwrites-blocks {
    /* height: 165px; */
    overflow: auto;
  }
  .custom-overwrites-modal-2 {
    /* margin: 0; */
    /* max-width: 360px!important; */
    width: 337px;
    /* height: 298px; */
  }
  .custom-overwrites-modal-3 {
    margin: 0;
    /* max-width: 360px!important; */
    /* width: 360px; */
    /* padding-top: 0px!important; */
    display: flex;
    align-items: center;
  }
  #overwrites_container .datepicker {
    width: 337px!important;
    /* width: 100%; */
  }
  .custom-overwrites-modal-container {
    width: 337px;
    height: 100%;
    display: flex;
    gap: 22px;
    flex-direction: column;
  }
  .custom-save {
    margin-bottom: 0px!important;
  }
  .dropdown.is-inline .dropdown-menu {
    height: 100%;
    width: 100%;
  }
  #overwrites_container .datepicker .dropdown-content {
    height: 100%;
  }
}
</style>
